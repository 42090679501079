import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import { Container, Heading } from 'components';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

function RefundPolicyPage({ data }) {
  const richText = JSON.parse(data.contentfulPages?.body.raw);

  return (
    <Layout pageTitle="Refund Policy">
      <Container>
        <Heading className="text-center">{data.contentfulPages?.title}</Heading>
        <div
          className="rich-text"
          dangerouslySetInnerHTML={{
            __html: documentToHtmlString(richText),
          }}
        />
      </Container>
    </Layout>
  );
}

export default RefundPolicyPage;

export const query = graphql`
  query refundPolicyPageQuery {
    contentfulPages(slug: { eq: "refund-policy" }) {
      title
      slug
      body {
        raw
      }
    }
  }
`;
